import React from 'react';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

import visimage from './img/vis.jpg';
import exampleImg from './img/chart1.gif';
import exampleImg2 from './img/chart2.gif';

import chart1 from './img/chart1.png';
import chart2 from './img/chart2.png';
import chart3 from './img/chart3.png';
import chart4 from './img/chart4.png';
import chart5 from './img/chart5.png';
import chart6 from './img/chart6.png';
import chart7 from './img/chart7.png';


export default class DataVisualizationPage extends React.Component {
  constructor() {
    super()
    this.myRef = "dataVizPage";

  }
  
  render() {
    return (
      <div id={this.myRef} style={{top: '-10px', height: '800px', position: 'relative', backgroundColor: 'rgb(232, 232, 234)'}}>
        <div id={this.myRef} style={{top: '400px', height: '400px', position: 'absolute', backgroundColor: 'rgb(232, 232, 234)', zIndex: 9, width: '100%'}}></div>
        <img src={visimage} style={{width: '100%', position: 'absolute'}}/>
        <BrowserView>
            <div style={{width: '1250px', marginLeft: 'auto', marginRight: 'auto', position: 'relative', zIndex: 10}}>
           
              <div id="dataViz">Data Visualization</div>
              <div id="dataVizSubtitle">Making Sense of Data Insights</div>
              
              <img src={exampleImg} id="dataVizPic"/>
              <img src={exampleImg2} id="dataVizPic2"/>
              <div id="dvMain">
                Incredible advancements in Data Science has made it easier than ever to find 
                insights and patterns in customer data. However, without proper visualization tools 
                much of that value could be lost in translation to human analysts and decisionmakers.  
                Edgetide specializes in data visualization consulting, design, and development of custom, 
                open source, and commercial visualization solutions.  We work within the customer’s 
                timeline and budget to provide maximum value and easy to use tools.
                <br /> <br />

                Let Edgetide help you with:
                <ul>
                  <li>Business Intelligence Integration</li>

                  <li>Geographic Information Systems</li>

                  <li>Custom Web Tools</li>

                  <li>Linked Data</li>

                  <li>Graphing and Charting</li>

                  <li>Ontology Visualization</li>

                  <li>Front End Integration</li>
                </ul>
                
                  
                </div>
              </div>
              <div id="dvImage">
                <img src={chart1} /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <img src={chart2} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <img src={chart3} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <img src={chart4} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <img src={chart5} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <img src={chart6} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <img src={chart7} />
              </div>
          
        
        </BrowserView>
        <MobileView>
            <div style={{width: '320px', marginLeft: 'auto', marginRight: 'auto', position: 'relative'}}>
              
              <div id="dataVizMobile">Data Visualization</div>
              <div id="dataVizSubtitleMobile">Making Sense of Data Insights</div>
              <img src={exampleImg} id="dataVizPicMobile"/>
              <img src={exampleImg2} id="dataVizPicMobile2"/>

              <div id="dvMainMobile">
                Incredible advancements in Data Science has made it easier than ever to find 
                insights and patterns in customer data. However, without proper visualization tools 
                much of that value could be lost in translation to human analysts and decisionmakers.  
                Edgetide specializes in data visualization consulting, design, and development of custom, 
                open source, and commercial visualization solutions.  We work within the customer’s 
                timeline and budget to provide maximum value and easy to use tools.

                <ul>
                  <li>Business Intelligence Integration</li>

                  <li>Geographic Information Systems</li>

                  <li>Custom Web Tools</li>

                  <li>Linked Data</li>

                  <li>Graphing and Charting</li>

                  <li>Ontology Visualization</li>

                  <li>Front End Integration</li>
                </ul>
                
                  
              </div>
              <div id="dvImageMobile">
                <img src={chart1} /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <img src={chart2} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <img src={chart3} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <img src={chart6} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <img src={chart7} />
              </div>
              
            </div>
        </MobileView>
        
        </div>
     
    );
  }
}
