import React from 'react';
import Plx from 'react-plx';
import VisibilitySensor from "react-visibility-sensor";

import {
  BrowserView,
  MobileView,
} from "react-device-detect";




export default class DataSciencePage extends React.Component {
  constructor() {
    super()
    this.myRef = "dataSciencePage";
    this.mainContent = [
      {
        start: 'self',
        duration: '50vh',
        properties: [
          {
            startValue: 0,
            endValue: 1,
            property: 'opacity',
          },
        ],
      }
    ];
  }

  state = {
    showBoxes: false
  }

  onChange = (isVisible) => {
    if (isVisible) {
      this.setState({showBoxes: true});
      this.setupBackground();
    } else {
      if (this.effect) this.effect.destroy();
    }
  }

  componentWillUnmount() {
    if (this.effect) this.effect.destroy();
  }

  setupBackground = () => {
    const item = document.getElementById(this.myRef);

    if (item) {
      this.effect = window.VANTA.TOPOLOGY({
        el: `#${this.myRef}`
      });
    } else {
      setTimeout(() => {
        this.setupBackground();
      }, 300);
    }
  }
  render() {

    const { showBoxes } = this.state;

    let display = {};

    if (!showBoxes) {
      display = {display: 'none'};
    }


    return (
      <div>
        <BrowserView>
          
          <div id={this.myRef} style={{top: '-10px', height: '800px', position: 'relative', backgroundColor: 'rgb(20, 35, 30)'}}>
            <div style={{width: '1250px', marginLeft: 'auto', marginRight: 'auto'}}>
              <div id="dataScience">Data Science</div>
              <div id="dataScienceSubtitle">Unlock Value in Big Data</div>
                <Plx 
                
                  parallaxData={ this.mainContent }>
                  <div id="dsMain">
                  <VisibilitySensor onChange={this.onChange}>
                    <span>
                    Data Science is one of the fastest growing fields in America.  Utilizing 
                    this technology correctly will allow an organization to turn massive amounts of raw data into 
                    predictions and insights that make business more profitable and efficient. Due to 
                    the many options, solutions, technologies, and methodologies available today, Data 
                    Science can be difficult for an organization to navigate.  Edgetide can help with 
                    the full spectrum of the Data Science pipeline to help you get up and running quickly,
                    so your investment can start adding value.  
                    <br/><br/> <br/><br/>
                  
                    Edgetide specializes in:
                      
                    </span>
                    </VisibilitySensor>
                  </div>
                </Plx>

                <div style={display}>
                    <div className="dsContent" style={{left: '90px', top: '450px'}}>
                        <b>Data Engineering</b> 

                        <br/><br/>
                        <span style={{fontSize: '12px'}}>Architecture Design and Development, ETL, Data Acquisition</span>
                         
                        
                    </div>
                    <div className="dsContent" style={{left: '50%', marginLeft: '-150px', top: '450px'}}>
                        <b>Data Storage Mechanisms</b> 

                        <br/> <br/>
                        <span style={{fontSize: '12px'}}>Relational Databases, NoSQL, Big Data, Cloud Storage</span>
                            
                    </div>
                    <div className="dsContent" style={{right: '90px', top: '450px'}}>
                        <b>Data Science in Cloud</b> 

                        <br/> <br/>
                        <span style={{fontSize: '12px'}}>Scale Data Processing and Analysis On Demand</span>
                              
                    </div>
                    <div className="dsContent" style={{left: '90px', top: '600px'}}>
                        <b>Data Preprocessing</b> 

                        <br/> <br/>
                        <span style={{fontSize: '12px'}}>Structured, Semi-Structured, and Unstructured Data</span>
                          
                        
                    </div>
                    <div className="dsContent" style={{left: '50%', marginLeft: '-150px', top: '600px'}}>
                        <b>Machine Learning</b> 

                        <br/> <br/>
                        <span style={{fontSize: '12px'}}>Neural Networks, Clustering, 
                        Decision Trees, Regression and Ensemble Modeling</span>
                          
                            
                    </div>
                    <div className="dsContent" style={{right: '90px', top: '600px'}}>
                          <b>Data Presentation</b> 

                          <br/> <br/>
                        <span style={{fontSize: '12px'}}>Structured, Semi-Structured, and Unstructured Data</span>
                            
                    </div>


                </div>
               


              </div>

            
          </div>
        
        </BrowserView>
        <MobileView>
          <div id={this.myRef} style={{top: '-10px', height: '800px', position: 'relative', backgroundColor: 'rgb(20, 35, 30)'}}>
              <div style={{width: '320px', marginLeft: 'auto', marginRight: 'auto'}}>
                <div id="dataScienceMobile">Data Science</div>
                <div id="dataScienceSubtitleMobile">Unlock Value in Big Data</div>
                <br /> <br />
                  <div id="dsMainMobile">
                    Data Science is one of the fastest growing fields in America and it's no wonder
                    with the power and capability it provides an industry or organization.  Utilizing 
                    this technology correctly will allow you to turn massive amounts of raw data into 
                    predictions and insights to make your business more profitable and efficient. Due to 
                    the many options, solutions, technologies, and methodologies available today, Data 
                    Science can be difficult for an organization to navigate.  Edgetide can help with 
                    the full spectrum of the Data Science pipeline to help you get up and running quickly,
                    so your investment can start adding value.  
                    <br/><br/>
                  
                  </div>
                  <div className="dsContent" style={{left: '50%', marginLeft: '-150px', top: '430px'}}>
                        <b>Machine Learning</b> 

                        <br/> <br/>
                        <span style={{fontSize: '12px'}}>Neural Networks, Clustering, 
                        Decision Trees, Regression and Ensemble Modeling</span>
                            
                  </div>
                  <div className="dsContent" style={{left: '50%', marginLeft: '-150px', top: '550px'}}>
                        <b>Data Engineering</b> 

                        <br/><br/>
                        <span style={{fontSize: '12px'}}>Architecture Design and Development, ETL, Data Acquisition</span>
                        
                  </div>

                  <div className="dsContent" style={{left: '50%', marginLeft: '-150px', top: '670px'}}>
                        <b>Data Storage Mechanisms</b> 

                        <br/> <br/>
                        <span style={{fontSize: '12px'}}>Relational Databases, NoSQL, Big Data, Cloud Storage</span>
                            
                    </div>
              </div>
          </div>

        </MobileView>
      

      </div>
      
    );
  }
}
