import React from 'react';


import {
  BrowserView,
  MobileView
} from "react-device-detect";


export default class Footer extends React.Component {
  constructor() {
    super()
    this.myRef = "dataVizPage";

  }
  
  render() {
    return (
      <span>
        <BrowserView>
        <div  
          style={{top: '-10px', height: '180px', position: 'relative', backgroundColor: 'rgb(247, 247, 249)'}}>
            <div style={{width: '1250px', marginLeft: 'auto', marginRight: 'auto', position: 'relative'}}>
              <div style={{color: '#222d3b', 
              fontSize: '14px', position: 'absolute', top: '50px', left: '50%', marginLeft: '-50px'}}>
                <div style={{fontSize: '18px'}}>Edgetide LLC</div>
                <div>9520 Berger Road</div>
                <div>Suite 212</div>
                <div>Columbia, MD 21048</div>
                <br />
                <a href="mailto:info@edgetide.com">info@edgetide.com</a>
              </div>
            </div>
        </div>
        </BrowserView>
        <MobileView>
        <div  
          style={{top: '-10px', height: '180px', position: 'relative', backgroundColor: 'rgb(247, 247, 249)'}}>
            <div style={{width: '350px', marginLeft: 'auto', marginRight: 'auto', position: 'relative'}}>
              <div style={{color: '#222d3b', 
              fontSize: '14px', position: 'absolute', top: '50px', left: '50%', marginLeft: '-50px'}}>
                <div style={{fontSize: '18px'}}>Edgetide LLC</div>
                <div>9520 Berger Road</div>
                <div>Suite 212</div>
                <div>Columbia, MD 21048</div>
                <br />
                <a href="mailto:info@edgetide.com">info@edgetide.com</a>
              </div>
            </div>
        </div>
        </MobileView>
      </span>
     
     
    );
  }
}
