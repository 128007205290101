import React from 'react';
import aiImage from './img/ai.png';
import VisibilitySensor from "react-visibility-sensor";

import {
  BrowserView,
  MobileView,
} from "react-device-detect";

export default class AIMLPage extends React.Component {
  constructor() {
    super()
    this.myRef = "aiPage";

  }
  componentDidMount() {

    this.setupBackground();
  }

  setupBackground = () => {
    const item = document.getElementById(this.myRef);

    if (item) {
      window.setupAndInitAIBlob();
    } else {
      setTimeout(() => {
        this.setupBackground();
      }, 300);
    }
  }

  onChange = (isVisible) => {
    if (isVisible) {
      console.log("draw blobby");
      window.drawBlobby = true;
    } else {
      window.drawBlobby = false;
    }
  }
  
  render() {
    return (
      <VisibilitySensor partialVisibility onChange={this.onChange}>
         <div>
        <BrowserView>
          <div style={{top: '-10px', height: '800px', position: 'relative', backgroundColor: 'white'}}>
            <div style={{width: '1250px', marginLeft: 'auto', marginRight: 'auto', position: 'relative'}}>
              <canvas style={{width: '1250px', height: '800px', position: 'absolute'}} id={this.myRef} />
              <div id="aiTitle">Artificial Intelligence</div>
              <div id="aiSubtitle">Machines Performing Human Tasks</div>
              <div id="aiMain">
                AI is a broad field that encompasses many disciplines and solutions that all perform tasks 
                normally reserved only for humans.  Edgetide specializes in a wide variety of AI 
                technologies from expert systems to knowledge graphs to machine learning (including deep 
                learning).  Through a combination of these technologies and techniques, Edgetide delivers 
                solutions that understand video, images, documents, social media, and many other data types 
                in many languages.

                
                <div>
                  <ul>
                    <li>Natural Language Processing/Natural Language Understanding</li>

                    <li>Speech Recognition</li>

                    <li>Machine Translation</li>

                    <li>Object Detection</li>

                    <li>Facial Recognition</li>

                    <li>Predictive Modeling</li>

                    <li>Cognitive Analytics</li>

                    <li>Search</li>

                    <li>Question/Answer Systems</li>

                    <li>Optical Character Recognition</li>
                  </ul>
                
                </div>

                <br />

                Edgetide's Orden AI combines these capabilities, integrated into a scalable 
                architecture. with an easy to use dashboard for visualizing data.  We’ve made it easy for 
                end users to utilize AI with massive amounts of data.

              </div>
              
            </div>
            <img id="aiImage" src={aiImage}></img>
          </div>
        </BrowserView>

        <MobileView>
        <div style={{top: '-10px', height: '800px', position: 'relative', backgroundColor: 'white'}}>
            <div style={{width: '350px', marginLeft: 'auto', marginRight: 'auto', position: 'relative'}}>
              <canvas style={{width: '650px', height: '400px', right: '-300px', top: '80px', position: 'absolute'}} id={this.myRef} />
              <div id="aiTitleMobile">Artificial Intelligence</div>
              <div id="aiSubtitleMobile">Machines Performing Human Tasks</div>
              <div id="aiMainMobile">
                AI is a broad field that encompasses many disciplines and solutions that all perform tasks 
                normally reserved only for humans.  Edgetide specializes in a wide variety of AI 
                technologies from expert systems to knowledge graphs to machine learning (including deep 
                learning).  Through a combination of these technologies and techniques, Edgetide delivers 
                solutions that understand video, images, documents, social media, and many other data types 
                in many languages.

                
                <div>
                  <ul>
                    <li>Natural Language Processing/Natural Language Understanding</li>

                    <li>Speech Recognition</li>

                    <li>Machine Translation</li>

                    <li>Object Detection</li>

                    <li>Facial Recognition</li>

                    <li>Predictive Modeling</li>

                    <li>Cognitive Analytics</li>

                    <li>Search</li>

                    <li>Question/Answer Systems</li>

                    <li>Optical Character Recognition</li>
                  </ul>
                
                </div>

                <br />

                Edgetide's Orden AI combines these capabilities, integrated into a scalable 
                architecture. with an easy to use dashboard for visualizing data.  We’ve made it easy for 
                end users to utilize AI with massive amounts of data.

              </div>
              
            </div>
            <img id="aiImageMobile" src={aiImage}></img>
          </div>

        </MobileView>
       </div>
      </VisibilitySensor>
    );
  }
}
