import React from 'react';
import animateScroll from 'animated-scroll-to';
import Plx from 'react-plx';
import './docs.scss';
//import MainPage from './MainPage';

import etLogo from './img/edgetideLogo.png';

import {
  Header, Image, Menu
} from 'semantic-ui-react';
import OrdenPage from './OrdenPage';
import CloudComputingPage from './CloudComputingPage';
import DataSciencePage from './DataSciencePage';
import DataVisualizationPage from './DataVisualization';
import AIMLPage from './AIMLPage';
import Footer from './Footer';

export default class App extends React.Component {
  handleScrollTop() {
    animateScroll(0, { minDuration: 8000 });
  }

  render() {
    return (
      <div >
        <div id='mainPage' />
        <Menu 
          attached="top" 
          size="mini" 
          style={{ 
            position: 'fixed', 
            top: '0px', 
            height: '45px', 
            width: '100%', 
            backgroundColor: 'white',
            zIndex: 99
          }}>
          <Menu.Item>
            <Header
              id="app-header"
              inverted
              floated="left"
              as="h2"
            >
               <Image 
                  style={{ position:'fixed', height: '40px', width: '120px', top: '2px' }} 
                  src={etLogo} 
                  size="massive" 
                />
            </Header>
          </Menu.Item>
          
          </Menu>
       
        <div >
          <OrdenPage />
          <CloudComputingPage />
          <DataSciencePage />
          <DataVisualizationPage />
          <AIMLPage />
          <Footer />
         
        </div>
      </div>
    );
  }
};

