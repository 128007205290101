import React from 'react';
import Plx from 'react-plx';

import oPic1 from './img/FC1.jpg';
import oPic2 from './img/salesTransactionDashboard.jpg';
import ordenLogo from './img/OrdenForLightBg.png';
import ordenVideo from './img/ordenVideo.jpg';

import {
  BrowserView,
  MobileView,
} from "react-device-detect";

const oPicDir = [
  {
    start: 'self',
    startOffset: 10,
    duration: 500,
    easing: [0.25, 0.1, 0.6, 1.5],
    properties: [
      {
        startValue: 90,
        endValue: 0,
        property: 'rotate',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'scale',
      },
    ],
  },
  {
    start: 'self',
    startOffset: '40px',
    duration: '300px',
    properties: [
      {
        startValue: 0,
        endValue: -330,
        unit: 'px',
        property: 'translateX',
      }
    ],
  },
];

const oPicDir2 = [
  {
    start: 'self',
    startOffset: 0,
    duration: 500,
    easing: [0.25, 0.1, 0.6, 1.5],
    properties: [
      {
        startValue: 90,
        endValue: 0,
        property: 'rotate',
      },
      {
        startValue: 0,
        endValue: 1,
        property: 'scale',
      },
    ],
  },
  {
    start: 'self',
    startOffset: '80px',
    duration: '350px',
    properties: [
      {
        startValue: 0,
        endValue: 330,
        unit: 'px',
        property: 'translateX',
      }
    ],
  },
];

const contentData1 = [
  {
    start: '50vh',
    duration: '60vh',
    properties: [
      {
        startValue: 0.2,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
];

export default class OrdenPage extends React.Component {
  state = {
    textOpacity: 0
  }

  render() {
    const {textOpacity} = this.state;
    return (
      <div style={{backgroundColor: "#f6f6f8", position: "relative", top: "0px", height: '800px'}}>
        <BrowserView>
          <div style={{width: '1250px', marginLeft: 'auto', marginRight: 'auto', position: 'relative'}}>
            <Plx 
              parallaxData={ contentData1 } 
              onPlxStart={()=> {this.setState({textOpacity: 1})}}
              onPlxEnd={()=> {this.setState({textOpacity: 0})}}
            >
              <div style={{position: 'absolute', right: '70px', top: '80px', width: '450px',
              fontSize: '16px', opacity: textOpacity}}>
                <img src={ordenLogo} style={{width: '270px'}}/>
                <div style={{color: '#05a6a1', fontSize: '20px'}}>Cognitive Analytics | Augmented NLU/NLP</div>
                <div style={{color: '#3c414e'}}>
                Edgetide’s product Orden AI, a scalable NLU and cognitive analytics platform, comprehends massive 
                amounts of data at scale and creates graph data structures of linked data for search, machine inference, 
                pattern recognition and other forms of data discovery
                </div>
              </div>

              <div style={{position: 'absolute', left: '70px', top: '360px', width: '450px',
              fontSize: '16px', opacity: textOpacity}}>
                <img style={{width: '450px'}} src={ordenVideo} />
              </div>
            </Plx>
            
            
            <div style={{ left: '50%', marginLeft: "-225px", position: "relative", top: '80px'}}>
            <Plx
              className='ordenPic'
              parallaxData={ oPicDir }
            >
              <img style={{width: '450px'}} src={oPic1}/>
              
            
            </Plx>
            <br />
            <Plx
              className='ordenPic'
              parallaxData={ oPicDir2 }
            >
              <img style={{width: '450px'}} src={oPic2}/>
              
            
            </Plx>

            </div>
            
          
          </div>
        
        </BrowserView>
        <MobileView>
          <div style={{width: '320px', marginLeft: 'auto', marginRight: 'auto', position: 'relative'}}>
            <img src={ordenLogo} style={{width: '270px'}}/>
                <div style={{color: '#05a6a1', fontSize: '20px'}}>Cognitive Analytics | Augmented NLU/NLP</div>
                <div style={{color: '#3c414e'}}>Edgetide’s product Orden AI, a scalable NLU and cognitive analytics platform, 
                comprehendsmassive amounts of data at scale and creates graph data structures of linked data for search, machine inference, 
                pattern recognition and other forms of data discovery
                </div>
                <br />
                <img style={{width: '310px'}} src={oPic1}/>
          </div>
          <br/>
          
        </MobileView>
       
        <a style={{position: 'absolute', bottom: '15px', left: '50%', marginLeft: '-70px'}} 
          className='coolButton' href="mailto:info@edgetide.com?subject=Orden AI">Learn More</a>
      </div>
    );
  }
}
