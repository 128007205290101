import React from 'react';
import Plx from 'react-plx';
import VisibilitySensor from "react-visibility-sensor";

import awsImage from './img/aws.png';
import azureImage from './img/azure.png';
import googleImage from './img/googleCloud.png';
import openStackImage from './img/openstack.png';


import {
  BrowserView,
  MobileView,
  isChrome,
  isSafari
} from "react-device-detect";

const uuidv1 = require('uuid/v1');

export default class CloudComputingPage extends React.Component {
  constructor() {
    super()
    this.myRef = "a" + uuidv1();

    this.textData3 = [
      {
        start: 'self',
        duration: '300px',
        properties: [
          {
            startValue: -500,
            endValue: 120,
            unit: 'px',
            property: 'translateX',
          },
        ],
      },
      {
        start: 'self',
        duration: '1300px',
        properties: [
          {
            startValue: 0.8,
            endValue: 1,
            property: 'opacity',
          },
        ],
      }
    ];

    this.textData4 = [
      {
        start: 'self',
        duration: '300px',
        properties: [
          {
            startValue: -500,
            endValue: 120,
            unit: 'px',
            property: 'translateX',
          },
        ],
      },
      {
        start: 'self',
        duration: '1300vh',
        properties: [
          {
            startValue: 0.8,
            endValue: 1,
            property: 'opacity',
          },
        ],
      }
    ];

    this.textData4 = [
      {
        start: 'self',
        duration: '300px',
        properties: [
          {
            startValue: -500,
            endValue: 120,
            unit: 'px',
            property: 'translateX',
          },
        ],
      },
      {
        start: 'self',
        duration: '140vh',
        properties: [
          {
            startValue: 0.8,
            endValue: 1,
            property: 'opacity',
          },
        ],
      }
    ];

    this.textData5 = [
      {
        start: 'self',
        duration: '300px',
        properties: [
          {
            startValue: -500,
            endValue: 120,
            unit: 'px',
            property: 'translateX',
          },
        ],
      },
      {
        start: 'self',
        duration: '1500px',
        properties: [
          {
            startValue: 0.8,
            endValue: 1,
            property: 'opacity',
          },
        ],
      }
    ];

    this.mainContent = [
      {
        start: 'self',
        duration: '70vh',
        properties: [
          {
            startValue: 0,
            endValue: 1,
            property: 'opacity',
          },
        ],
      }
    ];
  }
  state = {
    textOpacity1: 0,
    textOpacity2: 0,
    textOpacity3: 0,
    textOpacity4: 0,
    textOpacity5: 0,
    showTitle: 0
  }
 
  componentWillUnmount() {
    if (this.effect) this.effect.destroy()
  }

  onChange = (isVisible) => {
    if (isVisible) {
      this.setupBackground();
      this.setState({showTitle: 1});
    } else {
      if (this.effect) this.effect.destroy();
    }
  }

  totalPageChange = (isVisible) => {
    if (isVisible) {
     
    } else {
      this.setState({showTitle: 0});
    }
   
  }
  

  setupBackground = () => {
    const item = document.getElementById(this.myRef);

    if (item) {
      console.log("setup clouds");
      this.effect = window.VANTA.CLOUDS({
        el: `#${this.myRef}`,
        skyColor: 0x5ba3c0,
        cloudColor: 0xa4bbdb,
        sunColor: 0x60920,
        sunGlareColor: 0xca552e
      });
    } else {
      setTimeout(() => {
        this.setupBackground();
      }, 300);
    }
  }
  render() {
    const {textOpacity3, textOpacity4, textOpacity5, showTitle} = this.state;

    let cstyle = {};

    if (isChrome || isSafari) {
      cstyle = {top: '-40px'};
    }

    return (
      <div>
        <BrowserView>
          <VisibilitySensor partialVisibility onChange={this.totalPageChange}>
          <div style={{top: '-10px', height: '800px', backgroundImage: 'linear-gradient(#5ba3c0, #a4bbdb, white, white)'}}>
          
            <div 
              id={this.myRef} 
              style={{top: '-10px', height: '800px', backgroundImage: 'linear-gradient(#5ba3c0, #a4bbdb, white, white)'}}>
          
              <div style={{width: '1250px', marginLeft: 'auto', marginRight: 'auto'}}>
                <div id="ccTitle" style={{opacity: showTitle}}>Cloud Computing</div>
                <div id="ccSubtitle" style={{opacity: showTitle}}>Efficiently Processing and Scaling On demand</div>
                <div style={{position: 'relative', top: '230px'}}>
                    <Plx parallaxData={ this.mainContent }>
                    <div id="ccMain">
                    <VisibilitySensor onChange={this.onChange}>
                      <span>
                      Cloud computing empowers organizations to have whole data centers at their fingertips where they need only pay for the resources they use. While there is great potential value in performance, faster time to market, and lower cost, this is impossible to achieve without a knowledgeable system engineers, administrators, and other cloud resource familiar IT staff. Customers have many choices in cloud technologies and providers, Edgetide helps navigate these options or can even develop your solution end to end.
                    <br /><br/>
                    Edgetide has built systems using public and private clouds as well as hybrid solutions that scale into a public cloud as needed. We have experience with Amazon Web Services (AWS), Microsoft Azure, Google Cloud, and OpenStack.  Whatever your requirements may be, we can help meet your objectives  at the greatest value possible.  
                    

                      </span>
                    </VisibilitySensor>
                    </div>
                  </Plx>

                  <Plx 
                    onPlxStart={()=> {this.setState({textOpacity3: 1})}}
                    onPlxEnd={()=> {this.setState({textOpacity3: 0})}}
                    parallaxData={ this.textData3 }>
                    <div className="cloudContent" style={{opacity: textOpacity3}}>
                      <span className="cloudItem" style={cstyle}>
                        <h2>Cloud Consulting</h2>
                        Cloud Viability, Architecture Analysis, Design
                      </span>
                      
                    </div>
                  </Plx>
                  <Plx 
                    onPlxStart={()=> {this.setState({textOpacity4: 1})}}
                    onPlxEnd={()=> {this.setState({textOpacity4: 0})}}
                    parallaxData={ this.textData4 }>
                      <div className="cloudContent" style={{opacity: textOpacity4}}>
                      <span className="cloudItem" style={cstyle}>
                        <h2>Cloud Migration</h2>
                        Migrate your product to the cloud or switch cloud providers
                      </span>
                      </div>
                  </Plx>
                  <Plx 
                    onPlxStart={()=> {this.setState({textOpacity5: 1})}}
                    onPlxEnd={()=> {this.setState({textOpacity5: 0})}}
                    parallaxData={ this.textData5 }>
                      <div className="cloudContent" style={{opacity: textOpacity5}}>
                      <span className="cloudItem" style={cstyle}>
                        <h2>Cloud Development</h2>
                        
                        Edgetide develops to your requirements designed for cloud deployment
                      </span>
                      
                      </div>
                  </Plx>
                </div>
            
              </div>
              <div id="ccImage" style={{opacity: showTitle}}>
                <img src={awsImage} /> &nbsp;&nbsp;&nbsp;&nbsp;
                <img src={googleImage} />&nbsp;&nbsp;&nbsp;&nbsp;
                <img src={azureImage} />&nbsp;&nbsp;&nbsp;&nbsp;
                <img src={openStackImage} />
              </div>
              
            </div>
          

          </div>
          </VisibilitySensor>
        </BrowserView>
        <MobileView>
        <div 
            id={this.myRef} 
            style={{top: '-10px', height: '800px', backgroundImage: 'linear-gradient(#5ba3c0, #a4bbdb, white, white)'}}>
              <div style={{width: '320px', marginLeft: 'auto', marginRight: 'auto', position: 'relative'}}>
                <div id="ccTitleMobile" >Cloud Computing</div>
                <div id="ccSubtitleMobile" >Efficiently Processing and Scaling On demand</div>

                <div style={{position: 'relative', top: '120px'}}>
                    
                    <div id="ccMainMobile">
                    Cloud computing empowers organizations to have whole data centers at their fingertips where they need only pay for the resources they use. While there is great potential value in performance, faster time to market, and lower cost, this is impossible to achieve without a knowledgeable system engineers, administrators, and other cloud resource familiar IT staff. Customers have many choices in cloud technologies and providers, Edgetide helps navigate these options or can even develop your solution end to end.
                    <br /><br/>
                    Edgetide has built systems using public and private clouds as well as hybrid solutions that scale into a public cloud as needed. We have experience with Amazon Web Services (AWS), Microsoft Azure, Google Cloud, and OpenStack.  Whatever your requirements may be, we can help meet your objectives  at the greatest value possible.  
                    </div>
                </div>
                  <div id="ccImageMobile" >
                    <img style={{width: '60px'}} src={awsImage} /> &nbsp;&nbsp;&nbsp;&nbsp;
                    <img style={{width: '60px'}} src={googleImage} />&nbsp;&nbsp;&nbsp;&nbsp;
                    <img style={{width: '60px'}} src={azureImage} />&nbsp;&nbsp;&nbsp;&nbsp;
                    <img style={{width: '60px'}} src={openStackImage} />
                </div>
              </div>

        </div>

        </MobileView>
      </div>
    );
  }
}
